<template>
  <div>
    <h1 class="bold-header-text app-header">Company Profile</h1>
    <div class="base-header-text">
      <p>
        Manage your company's location and contact information effortlessly with our user-friendly
        Company Profile page. Enter, edit, and update details with ease. Your information
        auto-populates your application when you're ready to start, saving you time.
      </p>
    </div>

    <br />
    <div>
      <AdditionalSitesGrid />
    </div>
    <br /><br />
    <div><ManageContacts /></div>
  </div>
</template>
<script>
import AdditionalSitesGrid from "../../views/app/Company/AdditionalSitesGrid.vue";
import ManageContacts from "../../views/app/Company/ManageContacts.vue";
export default {
  components: {
    AdditionalSitesGrid,
    ManageContacts,
  },
};
</script>
